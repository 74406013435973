<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" day-date="true" />

    <v-row>
      <v-col md="12">
        <h1>COMPANY DIRECTORY</h1>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="items"
      :componentURL="componentURL"
      :searchValue="$route.query ? $route.query.search : ''"
      :search="true"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  components: { Datatable, CustomerInfo },

  data() {
    return {
      componentURL: "Employee",
      headers: [
        { text: "Nickname", value: "nickname" },
        { text: "Full Name", value: "full_name" },
        { text: "Title", value: "title" },
        { text: "Phone", value: "phone" },
        { text: "Text", value: "text" },
        { text: "Email", value: "email" },
      ],
      items: [],
    };
  },
};
</script>

<style></style>
